<template>

  <div>
    <b-row>
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-goal-overview :data="goalOverview" />
      </b-col>
      <b-col cols="4">
        <b-card
          no-body
          class="card-employee-task"
        >
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                Offboarding Process
              </b-card-title>
              <b-card-sub-title>Identities you might need to offboard</b-card-sub-title>
            </div>
          </b-card-header>
          <b-card-body>
            <app-timeline>
              <app-timeline-item
                title="Offboard Candidates"
                subtitle="23 Identities"
                icon="AwardIcon"
                time="+1 Today"
                variant="success"
              />

              <app-timeline-item
                title="Offboard Started"
                subtitle="42 Identities"
                icon="InfoIcon"
                time="-2 Today"
                variant="info"
              />

              <app-timeline-item
                title="Already Disabled"
                subtitle="15 Identities"
                icon="ClockIcon"
                time="-2 Today"
                variant="warning"
              />

              <app-timeline-item
                title="Already Deleted"
                subtitle="42 Identites"
                time="-4 Today"
                icon="UserIcon"
              />
              <app-timeline-item
                title="Offboarding Completed"
                subtitle="250 Identities"
                time="+5 Today"
                icon="UserIcon"
              />
            </app-timeline>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card
          no-body
          class="card-employee-task"
        >
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                Shared Accounts
              </b-card-title>
              <b-card-sub-title>Commercial networks and enterprises</b-card-sub-title>
            </div>
          </b-card-header>

          <!-- body -->
          <b-card-body>
            <div
              v-for="(employee) in employeeData"
              :key="employee.userFullName"
              class="employee-task d-flex justify-content-between align-items-center"
            >
              <b-media no-body>
                <b-media-aside class="mr-75">
                  <img
                    width="36"
                    :src="require(`@/assets/images/logos/${employee.service}.webp`)"
                  >
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h6 class="mb-0">
                    {{ employee.userFullName }}
                  </h6>
                  <small>{{ employee.designation }}</small>
                </b-media-body>
              </b-media>
              <div class="d-flex align-items-center">
                <small class="text-muted mr-75">{{ employee.duration }}</small>

              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-header>
        <div>
          <b-card-title class="mb-1">
            Offboarding Candidates
          </b-card-title>
          <b-card-sub-title>Identities you might need to offboard</b-card-sub-title>
        </div>
      </b-card-header>
      <div class="mx-2 mb-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-b-modal.offboardModal
                variant="primary"
              >
                <span class="text-nowrap">Offboard User</span>
              </b-button>
              <b-button
                v-b-modal.offboardModal
                variant="primary"
                class="ml-1"
              >
                <span class="text-nowrap">Offboard Application</span>
              </b-button>
              <b-modal
                id="offboardModal"
                title="Disabled Animation"
                size="lg"
                ok-title="Accept"
                hide-footer
              >
                <form-wizard-vertical />
              </b-modal>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchOffboardings"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(username)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveOffboardingRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(roles)="data">
          <div class="text-nowrap">
            <feather-icon
              v-for="role in data.item.roles"
              :key="role"
              :icon="resolveOffboardingRoleIcon(role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveOffboardingRoleVariant(role)}`"
            />
            <!-- <span class="align-text-top text-capitalize">{{ data.item.roles }}</span> -->
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveOffboardingStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(services)="data">
          <img
            v-for="service in data.item.services"
            :key="service"
            width="32"
            class="mr-1"
            :src="require(`@/assets/images/logos/${service}.webp`)"
            :text="avatarText(data.item.fullName)"
            :variant="`light-${resolveOffboardingRoleVariant(data.item.role)}`"
            :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
          >
        </template>

        <template #cell(tags)="data">
          <b-badge
            v-for="tag in data.item.tags"
            :key="tag.name"
            :variant="`light-${tag.color}`"
            class="mr-1"
          >
            {{ tag.name }}
          </b-badge>
        </template>

        <template #cell(lastActivity)="data">
          <span :class="`text-${data.item.lastActivity.color}`">{{ data.item.lastActivity.date }} {{ data.item.lastActivity.msg }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardHeader, BCardBody, BCardSubTitle, BCardTitle, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
// import OffboardingListFilters from './OffboardingListFilters.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import useOffboardingList from './useOffboardingList'
import offboardingStoreModule from './offboardingStoreModule'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
// import ChartjsLineChart from './ChartjsLineChart.vue'
import FormWizardVertical from './OffboardingWizard.vue'
// import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    // UsersListFilters,
    // UserListAddNew,
    BCardHeader,
    FormWizardVertical,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    BMediaAside,
    BMediaBody,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    EcommerceGoalOverview,
    vSelect,
    AppTimelineItem,
    AppTimeline,
  },
  data() {
    return {
      employeeData: [
        {
          service: 'Gsuite',
          userFullName: 'info@saasment.com',
          designation: 'Info User',
          duration: '2 Ex-Employees',
        },
        {
          service: 'Asana',
          userFullName: 'Louisa Norton',
          designation: 'UI Designer',
          duration: '2 Ex-Employees',
        },
        {
          service: 'Salesforce',
          userFullName: 'Jayden Duncan',
          designation: 'Java Developer',
          duration: '2 Ex-Employees',
        },
        {
          service: 'Office 365',
          userFullName: 'Cynthia Howell',
          designation: 'Anguler Developer',
          duration: '2 Ex-Employees',
        },
        {
          service: 'Okta',
          userFullName: 'Helena Payne',
          designation: 'Marketing',
          duration: '2 Ex-Employees',
        },
      ],
      goalOverview: {
        completed: '786,617',
        inProgress: '13,561',
        series: [83],
      },
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
      },
    }
  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data
      })
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-offboarding'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, offboardingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchOffboardings,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveOffboardingRoleVariant,
      resolveOffboardingRoleIcon,
      resolveOffboardingStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useOffboardingList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchOffboardings,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveOffboardingRoleVariant,
      resolveOffboardingRoleIcon,
      resolveOffboardingStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>
