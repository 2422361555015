import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useOffboardingsList() {
  // Use toast
  const toast = useToast()

  const refOffboardingListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'username', sortable: true },
    { key: 'email', sortable: true },
    { key: 'roles', sortable: true },
    { key: 'offboardedDate', label: 'Offboarded At', sortable: true },
    { key: 'lastActivity', label: 'Last Activity', sortable: true },
    {
      key: 'services',
      label: 'Services',
      formatter: title,
      sortable: true,
    },
    { key: 'tags', sortable: true },
  ]
  const perPage = ref(10)
  const totalOffboardings = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refOffboardingListTable.value ? refOffboardingListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOffboardings.value,
    }
  })

  const refetchData = () => {
    refOffboardingListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchOffboardings = (ctx, callback) => {
    store
      .dispatch('app-offboarding/fetchOffboardings', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { offboardings, total } = response.data

        callback(offboardings)
        totalOffboardings.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching offboardings list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveOffboardingRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveOffboardingRoleIcon = role => {
    if (role === 'subscriber') return 'OffboardingIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'OffboardingIcon'
  }

  const resolveOffboardingStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchOffboardings,
    tableColumns,
    perPage,
    currentPage,
    totalOffboardings,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOffboardingListTable,

    resolveOffboardingRoleVariant,
    resolveOffboardingRoleIcon,
    resolveOffboardingStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
