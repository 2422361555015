import { render, staticRenderFns } from "./Offboarding.vue?vue&type=template&id=531a1ce4&scoped=true&"
import script from "./Offboarding.vue?vue&type=script&lang=js&"
export * from "./Offboarding.vue?vue&type=script&lang=js&"
import style0 from "./Offboarding.vue?vue&type=style&index=0&id=531a1ce4&lang=scss&scoped=true&"
import style1 from "./Offboarding.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531a1ce4",
  null
  
)

export default component.exports